import React from "react"
import Layout from "../components/layout"
import Navbar from "../components/navBar"
import Footer from "../components/footer"
import FeaturedPost from "../components/featuredPost"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import {isMobile} from 'react-device-detect';

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentLoaded: true,
      isMobile: false,
    };
  }

  componentDidMount() {
    this.setState({ isMobile: isMobile })
  }

  render() {
    return (
      <Layout pageInfo={{ pageName: "index" }}>
        <SEO
          title="Home"
          keywords={['Brrrlabs', 'design', 'website', 'product design', 'poster design', 'social media design', 'jasa desain jogja', 'design studio']}
          ogUrl="/"
          ogImage={this.props.data.allContentfulPost.nodes[0].featuredImage.gatsbyImageData.images.sources[0].srcSet.split(",")[0].split(" ")[0]}
        />
        <Navbar menus={this.props.data.allContentfulMenu.edges} />
        <FeaturedPost posts={this.props.data.allContentfulPost.nodes} isMobile={this.state.isMobile}/>
        <Footer
          isMobile={this.state.isMobile}
          menus={this.props.data.allContentfulMenu.edges}
          siteInfo={this.props.data.allContentfulSiteInfo.nodes[0]}
          socials={this.props.data.allContentfulSocials.nodes}
        />
      </Layout>
    )
  }
}

export default IndexPage

export const Query = graphql`
query AllQuery {
  allContentfulMenu(sort: {fields: order, order: ASC}) {
    edges {
      node {
        name
        link
      }
    }
  }
  allContentfulSiteInfo {
    nodes {
      id
      copyrightText
      addressLink
      address {
        address
      }
      domain
      email
      fullName
      logoLarge {
        gatsbyImageData(formats: WEBP)
      }
      logoSmall {
        gatsbyImageData(formats: WEBP)
      }
      phone
      shortName
    }
  }
  allContentfulSocials {
    nodes {
      link
      name
    }
  }
  allContentfulPost(filter: {isFeatured: {eq: true}}, sort: {order: ASC, fields: postTitle}) {
    nodes {
      postTitle
      featuredImage {
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: WEBP, layout: CONSTRAINED)
        title
      }
    }
  }
}
`
