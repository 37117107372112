import React from "react"
import { Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"

class FeaturedPost extends React.Component {
  constructor(props) {
    super(props);
  }

  render(){
    const isMobile = this.props.isMobile;
    var featuredPostList = this.props.posts.map(function(post){
      if (isMobile) {
        return(
          <Row className="mx-auto h-100 w-100">
            <Col md={12} sm={12} lg={12} xl={12} xs={12} className="featured-post-col">
              <a href={"/project/"+post.postTitle}>
                <GatsbyImage image={post.featuredImage.gatsbyImageData} className="featured-post-img" style={{height:`80vh`}} imgStyle={{objectFit:`cover`}}/>
              </a>
              <a className="post-link" href={"/project/"+post.postTitle}>
                <h2 className="featured-post-title">{post.postTitle}</h2>
              </a>
            </Col>
          </Row>
        )
      }
      return(
        <Row className="mx-auto h-100 w-100">
          <Col md={12} sm={12} lg={12} xl={12} xs={12} className="featured-post-col">
            <a href={"/project/"+post.postTitle}>
              <GatsbyImage image={post.featuredImage.gatsbyImageData} className="featured-post-img" style={{height:`auto`}}/>
            </a>
            <a className="post-link" href={"/project/"+post.postTitle}>
              <h1 className="featured-post-title display-4">{post.postTitle}</h1>
            </a>
          </Col>
        </Row>
      )
    })
    return(
      <Row className="featured-post mx-auto">
        {featuredPostList}
      </Row>
    )
  }
}

export default FeaturedPost
